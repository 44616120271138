import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="404 not found" />
    <div className="container page">
      <h1>404 not found</h1>
      <h4>Woops. Looks like this page doesn't exist.</h4>
      <p>
        You can return{" "}
        <Link to="/">
          <b>back to the homepage</b>
        </Link>{" "}
        or{" "}
        <Link to="/contact">
          <b>contact us</b>
        </Link>{" "}
        if you believe something you are looking for should be here.
      </p>
    </div>
  </Layout>
)
